import { EventAttachment } from "../Calendar/projectCalendarTypes";
import { Order } from "../Materials/materialTypes";

/**
 * Formats a given delivery event into a "cleaned" version
 * TODO: Can be discarded after plotAPI.updateCalendarEventForProject takes a standard CalendarEvent object
 */
export function parseEventIntoSaveable(
    projectInfo: any,
    event: any
): {
    unique_token: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
    tagged_waypoint: string;
    tagged_area: string;
    tagged_equipments: number[];
    teams: number[];
    tagged_vendor: number;
    tagged_vendor_user: number;
    attachments: EventAttachment[];
    arrival_time: Date;
    departure_time: Date;
    orders: Order;
} {
    const cleanEvent = {
        unique_token: "null",
        title: "",
        description: "",
        start: new Date(),
        end: new Date(),
        tagged_waypoint: "",
        tagged_area: "",
        tagged_equipments: new Array<number>(),
        teams: new Array<number>(),
        tagged_vendor: null,
        tagged_vendor_user: null,
        attachments: new Array<EventAttachment>(),
        arrival_time: new Date(),
        departure_time: new Date(),
        orders: [],
    };

    if (event) {
        cleanEvent.unique_token = event.unique_token;

        cleanEvent.title = event.title;
        cleanEvent.description = event.description;

        if (!event.start) {
            event.start = new Date();
        }

        if (event.end) {
            event.end.setFullYear(event.start.getFullYear(), event.start.getMonth(), event.start.getDate());
        } else {
            event.end = event.start;
        }

        event.start.setSeconds(0);
        event.end.setSeconds(0);

        cleanEvent.start = event.start;
        cleanEvent.end = event.end;

        cleanEvent.tagged_waypoint = event.waypoint_uuid;

        cleanEvent.tagged_area = event.drop_off_area_uuid;

        cleanEvent.tagged_equipments = event.equipment
            ? event.equipment.map((equipment) => (equipment.id ? equipment.id : equipment))
            : [];

        cleanEvent.teams = event.teams ? event.teams.map((team) => (team.id ? team.id : team)) : [];

        if (projectInfo.delivery_approval_require_gc && projectInfo.owning_team_id != null) {
            cleanEvent.teams.push(projectInfo.owning_team_id);
            cleanEvent.teams = [...new Set(cleanEvent.teams)];
        }

        cleanEvent.tagged_vendor = event.vendor;
        cleanEvent.tagged_vendor_user = event.vendor_user;

        cleanEvent.attachments = event.attachments ? event.attachments : [];

        if (event.arrival_time) {
            event.arrival_time.setSeconds(0);
        }
        cleanEvent.arrival_time = event.arrival_time;

        if (event.departure_time) {
            event.departure_time.setSeconds(0);
        }
        cleanEvent.departure_time = event.departure_time;

        cleanEvent.orders = event.orders && projectInfo.use_lead_times ? event.orders : new Array<Order>();

        for (const order of cleanEvent.orders) {
            order.material.orders = [];
        }
    }

    // @ts-expect-error: Typescript fails to see that the types are equivalent
    return cleanEvent;
}
