/**
 * Takes in a string and determines whether that string is a valid phone number
 * @param phone_number a string that may or may not be a valid phone number
 * @returns boolean indicating true if the passed string is a valid phone number and false otherwise
 */
export function isPhoneNumber(phone_number: string): boolean {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    return re.test(phone_number);
}

/**
 * Formats a given phone number string into a "cleaned" version
 * @param phoneNumberString string assumed to be a phone number
 * @returns Formatted Phone number, returns null if not a valid phone number
 */
export function formatPhoneNumber(phoneNumberString: string): string {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        //const intlCode = match[1] ? "+" + match[1] + " " : "";
        const intlCode = ""; // Temp for if we want to show intl code again
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return "";
}

export function isEmail(email: string): boolean {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}
