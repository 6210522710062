import { trackPromise } from "react-promise-tracker";

/**
 * This is a very thin wrapper around the fetch API that sets some default
 * headers and credentials. It doesn't attempt to handle the responses at all,
 * that's still on you.
 *
 * @param url
 * @param options
 * @returns
 */
export function plotFetch(url: string, options: RequestInit = {}, track: boolean = false): Promise<Response> {
    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    // Merge supplied headers with defaults
    options.headers = {
        ...headers,
        ...options.headers,
    };

    // Default to including credentials
    options.credentials = options.credentials || "include";

    if (track) {
        return trackPromise(fetch(url, options));
    }

    return fetch(url, options);
}
