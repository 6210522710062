/**
 * Get a cookie by name from the cookie storage
 * @param cname string name for the cookie in cookie storage
 * @returns
 */
export function getCookie(cname): string {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**
 * Set a cookie by name, value, and expiration in the cache
 * @param cname string name for the cookie in cookie storage
 * @param cvalue
 * @param exdays
 */
export function setCookie(cname, cvalue, exdays = 365) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();

    const hostname = window.location.hostname.split(".");
    const domain = `.${hostname.slice(-2).join(".")}`;

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;Domain=" + domain + ";";
}
