import * as ApiRequest from "./components/Helper/ApiRequest";
import * as Calendar from "./components/Helper/Calendar";
import * as Contact from "./components/Helper/Contact";
import * as Cookie from "./components/Helper/Cookie";
import * as Dates from "./components/Helper/Dates";
import * as Map from "./components/Helper/Map";
import * as Styling from "./components/Helper/Styling";
import * as Url from "./components/Helper/Url";

const Helper = {
    ...ApiRequest,
    ...Calendar,
    ...Contact,
    ...Cookie,
    ...Dates,
    ...Map,
    ...Styling,
    ...Url,
};

export default Helper;
