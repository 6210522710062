import { isThisWeek, isYesterday } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

/**
 * Finds if a given date is outside a given range of time (inclusive),
 * if end is null then the check will be a range of 1 day
 * @param date A date to check with
 * @param start start of a given date range
 * @param end end of a given date range
 */
export function dateOutsideRange(date: Dayjs, start: Dayjs, end: Dayjs | null = null): boolean {
    // NOTE: This is really dumb that I need to set time parts to zero
    // date-fns purports to compare the dates and not the dates and times,
    // but this is clearly not the case
    const date_copy = dayjs(date);
    const start_copy = dayjs(start);

    if (end) {
        const end_copy = dayjs(end);

        return date_copy.isBefore(start_copy, "days") || date_copy.isAfter(end_copy, "days");
    }

    return !date_copy.isSame(start_copy, "day");
}

/**
 * Finds if a given date is within a given range of time (inclusive),
 * if end is null then the check will be a range of 1 day
 * @param date A date to check with
 * @param start start of a given date range
 * @param end end of a given date range
 */
export function dateInsideRange(date: Dayjs, start: Dayjs, end: Dayjs | null = null): boolean {
    return !this.dateOutsideRange(date, start, end);
}

export function notificationsDateStringFormatter(date: Dayjs) {
    date = dayjs(date);

    if (date.isSame(dayjs(), "day")) {
        return null;
    } else if (isYesterday(date.toDate())) {
        return "Yesterday ";
    } else if (isThisWeek(date.toDate())) {
        return date.format("dddd, ").toString();
    } else {
        return date.format("MMM, Do | ").toString();
    }
}

export function calculateDatePercentage(dateStart, dateEnd, dateValue) {
    return ((dateValue - dateStart) / (dateEnd - dateStart)) * 100;
}
